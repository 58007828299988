import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { ContentHeader } from "../../components";

const { confirm } = Modal;

interface DataType {
  key: string;
  name: string;
  phoneno: string;
  address: string;
  email: string;
  status: boolean;
}

const ManageSubAdmins = () => {
  const navigate = useNavigate();

  const renderSwitch = (status: any) => (
    <Switch size="small" defaultChecked={status} />
  );

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Sub admin?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Phone No",
      dataIndex: "phoneno",
      key: "phoneno",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate("/managesubadmins/subadmindetail/1");
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managesubadmins/edit/1`);
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: renderSwitch,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      phoneno: "+1 2531276",
      address: "New York No. 1 Lake Park",
      email: "john@gmail.com",
      status: false,
    },
    {
      key: "2",
      name: "Jim Green",
      phoneno: "+91 253321276",
      address: "London No. 1 Lake Park",
      email: "jim@gmail.com",
      status: true,
    },
    {
      key: "3",
      name: "Joe Black",
      phoneno: "+245 2531276",
      address: "Sydney No. 1 Lake Park",
      email: "joe@gmail.com",
      status: true,
    },
  ];

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Sub Admins"
        buttonText="Add Sub Admin"
        buttonNavigateTo="managesubadmins/add"
      />
      <div className="srch">
        <Card>
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search Users"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 55, borderRadius: 10 }}
              />
            </Col>
          </Row>
        </Card>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default ManageSubAdmins;
