import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";

import {
  usePutUpdateUserMutation,
  useLazyGetUserByIdQuery,
} from "../../../services/users";
import EditUserById from "../../../components/manageUsers/editUserById";
import { errorToast, successToast } from "../../../helpers";

const UsersForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const [editUser] = usePutUpdateUserMutation();
  const [getUserById] = useLazyGetUserByIdQuery();

  // const [profileImage, setProfileImage] = useState(null);
  // const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [values, setValues] = useState({
    fullName: "",
    email: "",
  });

  const onFinish = async (values: any) => {
    try {
      let body = {
        fullName: values.fullName,
        email: values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: phoneNo,
      };
      if (id) {
        const res = await editUser({ body, userId: id }).unwrap();
        if (res?.statusCode === 200) {
          navigate("/manage_users", { replace: true });
          successToast("User Updated Successfully");
          console.log(res, "done");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };
  console.log(id, "id");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const getUserDetails = async () => {
    try {
      const res = await getUserById(id).unwrap();
      console.log(res, "res");

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          email: res?.data[0]?.email,
          phone: res?.data[0]?.phone,
          fullName: res?.data[0]?.fullName,
        });
        setphoneNo(res?.data[0]?.phone || "");
        setPhoneCode(res?.data[0]?.countryCode || "");
        setValues({
          ...values,

          fullName: res?.data[0]?.fullName || "",
          email: res?.data[0]?.email || "",
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      email: values?.email,
      fullName: values?.fullName,
      phone: phoneNo,
    });
  }, [form]);

  return (
    <div style={{ padding: "40px 20px" }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Edit User</h2>
          <Button
            style={{
              background: "#1e90ff",
              width: 100,
              height: 40,
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage_users", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Full Name</h4>
                    <Input
                      value={values.fullName}
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(e) =>
                        setValues({ ...values, fullName: e.target.value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      // disabled
                      value={values.email}
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 200,
                    height: 50,
                  }}
                  type="primary"
                  htmlType="submit"
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Edit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default UsersForm;
