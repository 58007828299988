import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Hide X-axis grid lines
      },
    },
    // y: {
    //   grid: {
    //     display: false, // Hide Y-axis grid lines
    //   },
    // },
  },
};

const LineChart = ({ data }: { data: any }) => {
  return <Line options={options} data={data} />;
};

export default LineChart;
