import React from "react";
import { Button, Card, Col, Row, Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

type loanData = {
  fullName: string;
  loanType: string;
  emiDueDate: string;
  email: string;
  totalLoan: string;
  currentLoan: string;
};

interface DataType {
  id: number;
  currentLoan: string;
  repaidAmount: string;
  PendingEMI: string;
}
interface TransactionPropTypes {
  loanData: loanData;
  transactions: any;
  loading: boolean;
}

const TransactionDetails = ({
  loanData,
  transactions,
  loading,
}: TransactionPropTypes) => {
  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "Loan amount",
      dataIndex: "total",
      key: "total",
      render: (_, { currentLoan }) => (
        <div>
          <p>
            {currentLoan}
            <span style={{ color: "gray", paddingLeft: "5px" }}>USDT</span>
          </p>
        </div>
      ),
    },
    {
      title: "Paid EMI",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (_, { repaidAmount }) => (
        <div style={{ color: "green" }}>
          <p>
            {repaidAmount}
            <span style={{ paddingLeft: "5px" }}>USDT</span>{" "}
          </p>
        </div>
      ),
    },
    {
      title: "Pending EMI",
      dataIndex: "amountPending",
      key: "amountPending",
      render: (_, { PendingEMI }) => (
        <div>
          <p>
            {PendingEMI}
            <span style={{ color: "gray", paddingLeft: "5px" }}>USDT</span>
          </p>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "40px 20px", gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Transaction Details</h2>
            <Button
              style={{
                background: "#1e90ff",
                width: 100,
                height: 40,
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/managecurrency", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Full Name</h4>
                <h5>{loanData?.fullName || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Email</h4>
                <h5>{loanData?.email || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Total Loan</h4>
                <h5>{loanData?.totalLoan || "-"}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Current loan amount</h4>
                <h5>{loanData?.currentLoan || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Due date</h4>
                <h5>
                  {loanData?.emiDueDate
                    ? `${moment(loanData?.emiDueDate).utc().format("ll")}`
                    : "-"}
                </h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Loan Type</h4>
                <h5>{loanData?.loanType || "-"}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={24}>
                <h3>Transaction History</h3>
                <Table
                  style={{ marginTop: 20, marginBottom: 20 }}
                  pagination={false}
                  dataSource={transactions}
                  columns={columns}
                  rowKey="_id"
                />
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default TransactionDetails;
