import { END_POINTS } from "../helpers";
import { RootState } from "../store";
import type { CmsResponse, User } from "../types/User";
import emptySplitApi from "../utils/rtk";
import { DashboardTypes } from "../types/General";

type PostLoginBody = {
  key?: string;
  password?: string;
};

type PostLoginResponse = {
  statusCode: number;
  data?: User;
  message: string;
};
type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostForgotPasswordBody = {
  key?: string;
};

type PostVerifyOtpBody = {
  key?: string;
  otp: string;
};

type CommonResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type ChangePasswordBody = {
  oldPassword?: string;
  password?: string;
};

type UpdateProfileBody = {
  fullName: string;
  email: string;
  phone: string;
  countryCode: string;
};

type PostSendOtpResponse = {
  statusCode: number;
  message: string;
};

type PostSendOtpBody = {
  key?: string;
  // password?: string;
};

type getDashboardResponse = {
  statusCode: number;
  message: string;
  data: DashboardTypes;
};

type GetDashboardParams = {
  user: string;
  revenue: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<PostLoginResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postForgetPassword: builder.mutation<
      PostForgotPasswordResponse,
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postVerifyOtp: builder.mutation<PostVerifyOtpResponse, PostVerifyOtpBody>({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),

    postChangePassword: builder.mutation<CommonResponse, ChangePasswordBody>({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),

    postResetPassword: builder.mutation<CommonResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.resetpassword,
        method: "POST",
        body,
      }),
    }),

    putUpdateProfile: builder.mutation<CommonResponse, UpdateProfileBody>({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),

    postSendOtp: builder.mutation<PostSendOtpResponse, PostSendOtpBody>({
      query: (body) => ({
        url: END_POINTS.sendOtp,
        method: "POST",
        body,
      }),
    }),

    getProfile: builder.query<PostLoginResponse, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),

    getDashboard: builder.query<getDashboardResponse, GetDashboardParams>({
      query: ({ user, revenue }) => ({
        url: END_POINTS.getDashboard + "?user=" + user + "&revenue=" + revenue,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgetPasswordMutation,
  usePostVerifyOtpMutation,
  usePostChangePasswordMutation,
  usePostResetPasswordMutation,
  usePutUpdateProfileMutation,
  usePostSendOtpMutation,
  useLazyGetProfileQuery,
  useLazyGetDashboardQuery,
} = authApi;
