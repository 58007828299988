import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyGetLoanByIdQuery } from "../../../services/loan";
import { Loan } from "../../../types/User";
import LoanDetail from "../../../components/manageLoan/LoanDetails";

const ManageLoanDetail = () => {
  const { id } = useParams();

  const [getLoanDetail] = useLazyGetLoanByIdQuery();
  const [loanData, setLoanData] = useState<Loan>();
  const [loading, setLoading] = useState<boolean>(false);

  const getLoanDetails = async () => {
    try {
      setLoading(true);
      const res = await getLoanDetail(id).unwrap();
      setLoading(false);
      console.log(res, "getData");

      if (res?.statusCode === 200) {
        setLoanData(res?.data?.loan[0]);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getLoanDetails();
  }, []);

  return <LoanDetail loanData={loanData} loading={loading} />;
};

export default ManageLoanDetail;
