import AuthWrapper from "../../../layout/authWrapper";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store";
import {
  STORAGE_KEYS,
  errorToast,
  getData,
  storeData,
  successToast,
} from "../../../helpers";
import { usePostLoginMutation } from "../../../services/auth";
import { useEffect, useState } from "react";
import { setCredentials } from "../../../reducers/authSlice";
import { removeItem } from "../../../helpers/constants/storage";

const Login = () => {
  const [isRemember, setIsRemember] = useState(false);
  const [postLogin, postLoginData] = usePostLoginMutation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const body = {
      key: values.email,
      password: values.password,
    };
    if (isRemember) {
      storeData(STORAGE_KEYS.credentials, JSON.stringify(body));
    } else {
      await removeItem(STORAGE_KEYS.credentials);
    }

    try {
      const res = await postLogin(body).unwrap();
      console.log(res, "login");

      if (res?.statusCode === 200) {
        storeData(STORAGE_KEYS.token, res?.data?.token || "");
        storeData(STORAGE_KEYS.userData, JSON.stringify(res?.data));

        if (res?.data) {
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: res?.data?.token || null,
            })
          );
          console.log(res?.data?.token, "token");
        }

        navigate("/dashboard", { replace: true });
        successToast("Login Successfully");
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const onSelectChange = async () => {
    const creds = getData(STORAGE_KEYS.credentials);

    if (creds) {
      const credentials = await JSON.parse(`${creds}`);
      form.setFieldsValue({
        email: credentials?.email,
        password: credentials?.password,
      });
      setIsRemember(true);
    }
  };

  useEffect(() => {
    onSelectChange();
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="login_img">
          <img src="/static/images/crypto_logo.png" alt="" />
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            // label="Username"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            style={{ paddingBottom: "10px" }}
          >
            <Input
              style={{ width: "400px", height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            // label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              style={{ width: "400px", height: "50px" }}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
              }}
            >
              <Checkbox
                onChange={() => setIsRemember(!isRemember)}
                checked={isRemember}
              >
                Remember me
              </Checkbox>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Forgot password?
              </div>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#1e90ff",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={postLoginData?.isLoading}
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
