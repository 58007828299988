import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";

const { confirm } = Modal;
interface DataType {
  key: string;
  title: string;
  description: string;
  customerName: string;
  date: string;
  email: string;
}

const Queries = () => {
  const [visible, setVisible] = useState(false);

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: "10%",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      width: "15%",
      align: "center",
      render: () => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
          <Button onClick={handleShowModal}>
            <img
              style={{ height: 20, width: 20 }}
              src="/static/images/reply.png"
              alt="action"
            />
          </Button>
        </Space>
      ),
    },
  ];

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this query?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const data: DataType[] = [
    {
      key: "1",
      title: "Payment",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      customerName: "John",
      date: "April 24th 2022, 10:21 am",
      email: "john@gmail.com",
    },
    {
      key: "2",
      title: "Platform",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      customerName: "Nane",
      date: "Sep 17th 2023, 10:21 am",
      email: "nane@gmail.com",
    },
    {
      key: "3",
      title: "Booking",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      customerName: "Amo",
      date: "May 20th 2023, 10:21 am",
      email: "amo@gmail.com",
    },
  ];

  return (
    <div>
      <Row style={{ paddingTop: 20, paddingBottom: 20 }} gutter={16}>
        <Col xs={24} md={7}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
            style={{ height: 55, borderRadius: 10 }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={16} md={24}>
          <Table columns={columns} dataSource={data} />
        </Col>
      </Row>

      <Modal
        visible={visible}
        title="Reply"
        onCancel={handleHideModal}
        footer={[
          <Button key="cancel" onClick={handleHideModal}>
            Submit
          </Button>,
          // <Button key="ok" type="primary" onClick={handleHideModal}>
          //   OK
          // </Button>,
        ]}
      >
        <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
          <Input.TextArea
            placeholder="Description"
            style={{
              height: "100px",
              borderRadius: "8px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Queries;
