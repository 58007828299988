import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IntrestDetails from "../../../components/manageIntrest/IntrestDetails";
import { useLazyGetInterestByIdQuery } from "../../../services/loan";

const ManageInterestDetail = () => {
  const { id } = useParams();
  const [getLoanById] = useLazyGetInterestByIdQuery();
  const [interestDetails, setInterestDetails] = useState<any>();

  const getInterestDetails = async () => {
    try {
      const res = await getLoanById(id).unwrap();
      console.log(res, "getData");

      if (res?.statusCode === 200) {
        setInterestDetails(res?.data[0]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInterestDetails();
  }, []);

  return <IntrestDetails interestDetails={interestDetails} />;
};

export default ManageInterestDetail;
