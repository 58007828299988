import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetUserByIdQuery } from "../../../services/users";
import { User } from "../../../types/User";
import UserDetailById from "../../../components/manageUsers/userDetails";

const UserDetails = () => {
  const { id } = useParams();
  console.log(id);
  const [getUserById] = useLazyGetUserByIdQuery();
  const [userDetail, setUserDetail] = useState<any>();

  const getUserDetails = async () => {
    try {
      const res = await getUserById(id).unwrap();
      console.log(res, "getData");

      if (res?.statusCode === 200) {
        setUserDetail(res?.data[0]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return <UserDetailById userDetail={userDetail} />;
};

export default UserDetails;
