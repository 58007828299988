export const API_URL: string =
  "https://decentralizedbankapi.appgrowthcompany.com/api/v1/admin/";
// export const API_URL: string =
//   process.env.REACT_APP_API_URL ||
//   "https://decentralizedbankapi.appgrowthcompany.com/api/v1/admin/";

export const IMAGE_UPLOAD: string =
  "https://decentralizedbankapi.appgrowthcompany.com/api/v1/user/upload";

export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  changePassword: "changePassword",
  resetpassword: "resetpassword",
  updateProfile: "updateProfile",
  sendOtp: "sendOtp",
  getProfile: "getProfile",
  getUserList: "getUserList",
  getUserById: "getUserById",
  deleteUser: "deleteUser",
  editUser: "editUser",
  getAllLoan: "getAllLoan",
  blockUnblockUser: "blockUnblockUser",
  getLoanById: "getLoanById",
  approveLoan: "approveLoan",
  rejectLoan: "rejectLoan",
  deleteLoan: "deleteLoan",
  getFaq: "getFaq",
  addFaq: "addFaq",
  deleteFaq: "deleteFaq",
  getDashboard: "getDashboard",
  upload: "upload",
  addCms: "addCms",
  updateCms: "updateCms",
  getCms: "getCms",
  getAllfund: "getAllfund",
  addfund: "addfund",
  Setting: "Setting",
  getSetting: "getSetting",
  getAllTransaction: "getAllTransaction",
  getTransactionById: "getTransactionById",
  getLoanType: "getLoanType",
  getLoanTypeById: "getLoanTypeById",
  deleteLoanType: "deleteLoanType",
  updateLoanType: "updateLoanType",
  logoutUserById: "logoutUserById",
  setActiveOrInactive: "setActiveOrInactive",
  addLoanType: "addLoanType",
};
