import React from "react";
import { Button, Col, Form, Input, Row, Select, Card } from "antd";
import ContentHeader from "../ContentHeader";

interface MyComponentProps {
  onClick: () => void;
  setDeposit: React.Dispatch<React.SetStateAction<string>>;
  setCurrencyType: React.Dispatch<React.SetStateAction<string>>;
}

const DepositForm: React.FC<MyComponentProps> = ({
  onClick,
  setDeposit,
  setCurrencyType,
}) => {
  const url = window.location.pathname;
  const path = url.includes("edit");

  return (
    <div>
      <ContentHeader
        heading={"Add Deposit"}
        buttonText="Back"
        buttonNavigateTo="managedeposit"
      />
      <Card>
        <Form
          name="basic"
          style={{
            maxWidth: "80%",
          }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item name="add deposit">
                <div>
                  <h4>Crypto Currency</h4>
                  <Input
                    value={"USDT"}
                    placeholder="Crypto currency"
                    style={{ height: "55px" }}
                    onChange={(e) => {
                      setCurrencyType(e.target.value);
                    }}
                    disabled
                  />
                  {/* <Select
                    // defaultValue="Option 1"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    placeholder={"Choose currency"}
                    options={options}
                  /> */}
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="add deposit" rules={[{ required: true }]}>
                <div>
                  <h4>Deposit </h4>
                  <Input
                    placeholder="Deposit"
                    onChange={(e) => {
                      setDeposit(e.target.value);
                    }}
                    style={{ height: 55 }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#1e90ff",
                  width: 200,
                  height: 50,
                }}
                type="primary"
                htmlType="submit"
                onClick={onClick}
              >
                {path ? "Save" : "Add"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default DepositForm;
