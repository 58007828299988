import { useEffect, useState } from "react";
import { Card, Col, Input, Row, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Web3 from "web3";

import {
  useLazyGetAllDepositQuery,
  useAddDepositMutation,
} from "../../services/loan";
import {
  ABI,
  ContractAddress,
  ABIUSDT,
  ContractAddressUsdt,
} from "../../Web3_interact/configuration";
import { ContentHeader } from "../../components";
import { successToast } from "../../helpers";

const ManageDeposit = () => {
  const [getDeposit, getDepositData] = useLazyGetAllDepositQuery();

  const [allDepositData, setAllDepositData] = useState([]);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState<any>();
  const [contractUsdt, setContractUSDT] = useState<any>();
  const [result, setResult] = useState();
  const [mintAmount, setMintAmount] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  interface DataType {
    key: string;
    deposit: string;
    status: boolean;
    from: string;
    to: string;
    date: string;
    transactionDate: string;
    cryptoCurrency: string;
    adminAddress: string;
    bankAddress: string;
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllDepositDetails(page);
    console.log(page, "page");
  };

  async function handleMint() {
    try {
      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();
      const user = accounts[0];
      // console.log(contract.methods.approveLoan(), "APPROVE LOAN");
      // Estimate gas limit for the transaction
      // const gasLimit = await contract.methods
      //   .approveLoan(address, loanType.toLowerCase())
      //   .estimateGas({
      //     from: "0x03761FcaaCdf1bf880eB00b1f1Fc0ef21286099B",
      //   });
      // console.log(loanType, "*******");

      // console.log("Estimated gas limit:", gasLimit);

      // Get the current gas price from the network
      // const gasPrice = await web3.eth.getGasPrice();
      // console.log("Current gas price:", gasPrice);

      // Send the transaction to the contract with gas options
      const result = await contractUsdt.methods
        .mint(user, parseInt(mintAmount))
        .send({
          from: "0x03761FcaaCdf1bf880eB00b1f1Fc0ef21286099B",
          // gas: gasLimit,
          // gasPrice: gasPrice,
        });

      setResult(result);

      console.log("Minted successfully", result);
      successToast("Minted successfully");
    } catch (error) {
      console.error("Error", error);
    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Crypto Currency",
      dataIndex: "cryptoCurrency",
      key: "cryptoCurrency",
      align: "center",
      width: "20%",

      // render: (_, { coin, icon, symbol }) => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <div className="table_icon">{icon}</div>
      //     {/* <span>{symbol}</span> */}
      //     <span>{coin}</span>
      //   </div>
      // ),
      render: (_, { CurrencyType }: any) => (
        <div>
          <span>{`${CurrencyType || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Deposit",
      key: "deposit",
      dataIndex: "deposit",
      align: "center",
      width: "20%",
      render: (_, { deposit }) => (
        <div>
          <p>
            {deposit}
            <span style={{ color: "gray", paddingLeft: "5px" }}>USDT</span>
          </p>
        </div>
      ),
    },
    {
      title: "Date & Time",
      key: "date",
      dataIndex: "date",
      align: "center",
      width: "20%",
      render: (_, { transactionDate }) => (
        <div>
          <span>{`${
            moment(transactionDate).utc().format("YYYY-MM-DD / HH:mm") || "N/A"
          }`}</span>
        </div>
      ),
    },

    {
      title: "From",
      key: "from",
      dataIndex: "from",
      align: "center",
      width: "20%",
      render: (_, { adminAddress }) => (
        <div>
          <p>{adminAddress || "-"}</p>
        </div>
      ),
    },
    {
      title: "To",
      key: "to",
      dataIndex: "to",
      align: "center",
      width: "20%",
      render: (_, { bankAddress }) => (
        <div>
          <p>{bankAddress || "-"}</p>
        </div>
      ),
    },
  ];

  const getAllDepositDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getDeposit({
        page: page,
        limit: 10,
        search: search || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllDepositData(res?.data?.fund);
        setTotalCount(res?.data?.count);
        console.log(res?.data, "resList");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (search == "") {
      getAllDepositDetails(1);
    }
  }, []);

  useEffect(() => {
    const web3 = new Web3(window.ethereum);
    const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
    const contractInstanceUsdt = new web3.eth.Contract(
      ABIUSDT,
      ContractAddressUsdt
    );
    console.log(contractInstance);

    setContract(contractInstance);
    setContractUSDT(contractInstanceUsdt);
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader
        heading="Manage Funds"
        buttonText="Add Deposit"
        buttonNavigateTo="managedeposit/depositform/add"
      />
      <Card>
        <div className="srch">
          <Row
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getAllDepositDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 45, borderRadius: 10 }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 90,
                    height: 43,
                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  search
                </Button>
              </Form>
            </Col>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getAllDepositDetails(1)}
              >
                <Input
                  placeholder="Enter amount"
                  style={{ height: 45, borderRadius: 10 }}
                  onChange={(e) => setMintAmount(e.target.value)}
                />
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 90,
                    height: 43,
                    margin: "auto",
                  }}
                  type="primary"
                  onClick={handleMint}
                >
                  Mint
                </Button>
              </Form>
            </Col>
            <Col xs={24} md={4}>
              <Button
                style={{
                  background: "#1e90ff",
                  minWidth: 100,
                  height: 40,
                }}
                type="primary"
                onClick={() => {
                  navigate("/managedeposit/totalBalance/details", {
                    replace: true,
                  });
                }}
              >
                Check Balance
              </Button>
            </Col>
          </Row>

          <Table
            columns={columns}
            pagination={pagination}
            dataSource={allDepositData}
            loading={loading}
            rowKey="_id"
          />
        </div>
      </Card>
    </div>
  );
};

export default ManageDeposit;
