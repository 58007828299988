import { Card, Col, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import { LineChart } from "../../components/charts";
import RevenueChart from "../../components/charts/RevenueChart";
import UsersChart from "../../components/charts/UsersChart";
import { useLazyGetDashboardQuery } from "../../services/auth";
import { DashboardTypes } from "../../types/General";

const Analytics = () => {
  const [getDashboard] = useLazyGetDashboardQuery();

  const [dashboardDetails, setDashboardDetails] = useState<DashboardTypes>();
  const [name, setName] = useState<string | undefined>();

  const [selectedFilter, setSelectedFilter] = useState("monthly");
  const [selectedFilterUser, setSelectedFilterUser] = useState("monthly");
  const [revenueGraphValues, setRevenueGraphValues] = useState<string[]>([]);
  const [graphValues, setGraphValues] = useState<string[]>([]);

  const handleFilterChange = (value: any) => {
    setSelectedFilter(value);
  };
  const handleFilterChangeUser = (value: any) => {
    setSelectedFilterUser(value);
  };

  const showDataHandler = async () => {
    try {
      const payload = await getDashboard({
        user: selectedFilterUser,
        revenue: selectedFilter,
      }).unwrap();
      console.log(payload?.data, "GET DASHBOARD");
      setDashboardDetails(payload?.data);

      const details = payload?.data;
      if (payload?.statusCode === 200) {
        if (name === "left") {
          // setRevenueNames(details?.revenueData);
          setRevenueGraphValues(details?.revenueData);
        } else if (name === "right") {
          // setNames(details?.userData);
          setGraphValues(details?.userData);
        } else {
          // setNames(details?.userData);
          setGraphValues(details?.userData);

          setRevenueGraphValues(details?.revenueData);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    showDataHandler();
  }, [selectedFilter]);

  useEffect(() => {
    showDataHandler();
  }, [selectedFilterUser]);

  const labels: never[] = [];

  const labels2: never[] = [];

  const graphData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenueGraphValues,
        borderColor: "#1e90ff",
        backgroundColor: "#1e90ff",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const graphCustomerData = {
    labels2,
    datasets: [
      {
        label: "Users",
        data: graphValues,
        borderColor: "#1e90ff",
        backgroundColor: "#1e90ff",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Analytics" />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <RevenueChart
            graphData={graphData}
            onFilterChange={handleFilterChange}
            setName={setName}
          />
        </Col>
        <Col xs={24} md={12}>
          <UsersChart
            graphCustomerData={graphCustomerData}
            onFilterChange={handleFilterChangeUser}
            setName={setName}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
