import { Button, Card, Col, Form, Input, Row } from "antd";
import { ContentHeader } from "../../components";

const Notifications = () => {
  const { TextArea } = Input;

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader
        heading="Notifications"
        buttonText="View History"
        buttonNavigateTo="notifications/history"
      />
      <Card>
        <Form
          name="basic"
          style={{
            maxWidth: "80%",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Title!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Title</h4>
                  <Input placeholder="Title" style={{ height: 55 }} />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <h4>Description</h4>
                  <TextArea rows={8} placeholder="Description" />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              style={{
                background: "#0C163F",
                width: 100,
                height: 40,
                marginTop: 30,
              }}
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Notifications;
