import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import type { ColumnsType } from "antd/es/table";

const { Option } = Select;

interface DataType {
  key: string;
  name: string;
}

const FormData = (props: any) => {
  const [form] = Form.useForm();

  // const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as string[]);
  const [selectedRowView, setSelectedRowView] = useState([] as string[]);

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const handleChange = (value: any) => {
    // console.log(`Selected ${value}`);
    form.setFieldsValue({
      role: value,
    });
    form.validateFields(["role"]);
  };

  const handleCityChange = (value: any) => {
    // console.log(`Selected ${value}`);
    form.setFieldsValue({
      city: value,
    });
    form.validateFields(["city"]);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onSelectChange = (key: any) => {
    if (selectedRowKeys.includes(key)) {
      setSelectedRowKeys((selectedRowKeys) =>
        selectedRowKeys.filter((s) => s !== key)
      );
    } else {
      setSelectedRowKeys((selectedRowKeys) => [...selectedRowKeys, key]);
    }
  };

  const onSelectViewChange = (key: any) => {
    if (selectedRowView.includes(key)) {
      setSelectedRowView((selectedRowView) =>
        selectedRowView.filter((s) => s !== key)
      );
    } else {
      setSelectedRowView((selectedRowView) => [...selectedRowView, key]);
    }
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    console.log(
      values,
      errorFields,
      outOfDate,
      "values, errorFields, outOfDate"
    );
  };

  const data: DataType[] = [
    {
      key: "1",
      name: "Dashboard",
    },

    {
      key: "3",
      name: "Manage Users",
    },
    {
      key: "4",
      name: "Manage Deposits",
    },
    {
      key: "5",
      name: "Manage Loans",
    },
    {
      key: "6",
      name: "Manage Funds",
    },
    {
      key: "7",
      name: "Manage Interest",
    },
    {
      key: "8",
      name: "Notifications",
    },
    {
      key: "9",
      name: "Customer Support",
    },
    {
      key: "10",
      name: "Manage CMS",
    },
    {
      key: "11",
      name: "Manage FAQ",
    },
    {
      key: "12",
      name: "Settings",
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Modules",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Add/Edit",
      dataIndex: "",
      key: "",
      render: ({ key }: any) => {
        return (
          <Checkbox
            onChange={() => onSelectChange(key)}
            checked={selectedRowKeys.includes(key) || false}
          />
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: ({ name, key }: any) => {
        return (
          <Checkbox
            onChange={() => onSelectViewChange(key)}
            checked={selectedRowView.includes(key) || false}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Form
        name="basic"
        form={form}
        style={{
          maxWidth: "80%",
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h4>Image</h4>
            <Form.Item
              name="upload"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload name="logo" action="/upload.do" listType="picture">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
                {/* {profileImage ? <img src={profileImage} /> : <></>} */}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                  whitespace: true,
                },
                {
                  min: 3,
                  message: "Name must be at least 3 characters",
                },
              ]}
            >
              <div>
                <h4>Full Name</h4>
                <Input placeholder="Full Name" style={{ height: 55 }} />
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  min: 8,
                  message: "Phone number must be at least 8 characters",
                },
              ]}
            >
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <div>
                <h4>Email</h4>
                <Input
                  // disabled
                  style={{ height: 55 }}
                  placeholder="E-mail"
                />
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 7,
                  message: "Password must be at least 7 characters",
                },
              ]}
            >
              <div>
                <h4>Password</h4>
                <Input.Password
                  // disabled
                  style={{ height: 55 }}
                  placeholder="Password"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <div>
                <h4>Role</h4>
                <Select
                  // defaultValue="Option 1"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  placeholder={"Choose Role"}
                >
                  <Option value="Option 1">Finance</Option>
                  <Option value="Option 2">Manager</Option>
                  <Option value="Option 3">sadasd</Option>
                </Select>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please select a City",
                },
              ]}
            >
              <div>
                <h4>City</h4>
                <Select
                  // defaultValue="Option 1"
                  style={{ width: "100%" }}
                  onChange={handleCityChange}
                  placeholder={"Choose city"}
                >
                  <Option value="Option 1">Mohali</Option>
                  <Option value="Option 2">Mumbai</Option>
                  <Option value="Option 3">Goa</Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Table
              style={{ marginTop: 20, marginBottom: 20 }}
              pagination={false}
              dataSource={data}
              columns={columns}
            />
          </Col>
        </Row>

        <Form.Item>
          <div style={{ marginTop: 20 }}>
            <Button
              style={{
                background: "#0C163F",
                width: 200,
                height: 50,

                margin: "auto",
              }}
              type="primary"
              htmlType="submit"
              // onClick={() => {
              //   navigate("/manageUsers", { replace: true });
              // }}
            >
              {props?.path ? "Edit" : "Add"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormData;
