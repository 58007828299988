import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import {
  usePutUpdateSettingsMutation,
  useLazyGetSettingQuery,
} from "../../services/cms";
import { errorToast, successToast } from "../../helpers";
import { ContentHeader } from "../../components";

const Settings = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState("percentage");
  const [updateSetting] = usePutUpdateSettingsMutation();
  const [getSetting] = useLazyGetSettingQuery();

  const [platFormFees, setPlatFormFees] = useState<number>();

  const onFinish = async (values: any) => {
    let body = {
      plateFormFeeType: type,
      plateFormFee: values?.platformFees,
    };
    try {
      const res = await updateSetting(body).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
      }
    } catch (error: any) {
      console.log(error, "error");
      errorToast(error?.message);
    }
  };

  const getCmsDetail = async () => {
    try {
      const res = await getSetting({}).unwrap();
      console.log(res, "SETTING");

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          platformFeesType: res?.data[0]?.plateFormFeeType,
          platformFees: res?.data[0]?.plateFormFee,
        });
        setPlatFormFees(res?.data[0]?.plateFormFee);
        // setPhoneCode(res?.data?.countryCode);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Settings" />
      <Card>
        <Form onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col xs={24} md={6}>
              <h4>Platform Fees Type</h4>
              <Form.Item
                name="platformFeesType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  defaultValue={type}
                  style={{ height: 75, width: "100%" }}
                  onChange={(val) => setType(val)}
                  placeholder="Type"
                  options={[
                    { value: "percentage", label: "Percentage" },
                    { value: "flat", label: "Flat" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={6}>
              <h4>Platform Fees</h4>
              <Form.Item
                name="platformFees"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  prefix={type === "flat" ? <p>$</p> : null}
                  suffix={type === "percentage" ? <p>%</p> : null}
                  placeholder="Platform Fees"
                  style={{ height: 55 }}
                  onChange={(e) => {
                    setPlatFormFees(parseInt(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            style={{
              background: "#1e90ff",
              width: 100,
              height: 40,
            }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form>

        {/* <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={(key: string) => setActiveTab(key)}
        /> */}
      </Card>
    </div>
  );
};

export default Settings;
