import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { usePutUpdateLoanTypeMutation } from "../../../services/loan";
import { useLazyGetInterestByIdQuery } from "../../../services/loan";
import { errorToast, successToast } from "../../../helpers";
import { ContentHeader } from "../../../components";

const ManageIntrestForm = () => {
  const url = window.location.pathname;
  const path = url.includes("edit");
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [updateLoanType, updateLoanTypeMutation] =
    usePutUpdateLoanTypeMutation();
  const [getLoanTypeById] = useLazyGetInterestByIdQuery();

  const [loanType, setLoanType] = useState("");
  const [interestRate, setInterestRate] = useState("");

  const onFinish = async (values: any) => {
    try {
      let body = {
        loanType: values.loanType,
        interestRate: parseInt(interestRate),
      };
      if (id) {
        const res = await updateLoanType({ body, userId: id }).unwrap();
        if (res?.statusCode === 200) {
          navigate("/manageinterest", { replace: true });
          successToast(res?.message);
          console.log(interestRate, "done");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const getInterest = async () => {
    try {
      const res = await getLoanTypeById(id).unwrap();
      console.log(res, "res");

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          loanType: res?.data[0]?.loanType,
          interestRate: res?.data[0]?.interestRate,
        });
        setLoanType(res?.data[0]?.loanType);
        setInterestRate(res?.data[0]?.interestRate);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInterest();
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <div>
        <ContentHeader
          heading={"Edit Interest"}
          buttonText="Back"
          buttonNavigateTo="manageinterest"
        />
        <Card>
          <Form
            name="basic"
            style={{
              maxWidth: "80%",
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="loanType">
                  <div>
                    <h4>Loan Type</h4>
                    {/* <Select
                    // defaultValue="Option 1"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    placeholder={"Choose currency"}
                    options={options}
                  /> */}
                    <Input
                      value={loanType}
                      placeholder="Loan type"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setLoanType(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="interest">
                  <div>
                    <h4>Interest rate </h4>
                    <Input
                      value={interestRate}
                      placeholder="Interest rate"
                      suffix="%"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setInterestRate(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div style={{ marginTop: 20 }}>
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 200,
                    height: 50,
                  }}
                  type="primary"
                  htmlType="submit"
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  {"Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ManageIntrestForm;
