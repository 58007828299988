import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Form,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetUsersQuery,
  useDeleteUserMutation,
  useLazyPostBlockUserQuery,
} from "../../services/users";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { errorToast, successToast } from "../../helpers";

const { confirm } = Modal;

interface DataType {
  _id: string;
  key: string;
  name: string;
  phone: string;
  address: string;
  email: string;
  status: boolean;
}

const ManageUsers = () => {
  const userData = useAuth();

  const [userList, userListData] = useLazyGetUsersQuery();
  const [manageUserData, setManageUserData] = useState([]);
  const [deleteUser] = useDeleteUserMutation();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [blockUser, blockUserData] = useLazyPostBlockUserQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    getUserDetails(pages);
    console.log(pages, "page");
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteUser({ userId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("User Deleted Successfully");
        getUserDetails(1);
        setCurrentPage(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    try {
      const res = await blockUser({ userId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getUserDetails(1);
        successToast(
          isBlocked
            ? "User Deactivated Successfully!"
            : "User Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
      render: (_, { fullName }: any) => (
        <div>
          <span>{`${fullName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { phone, countryCode }: any) => (
        <div>
          <span>{`${phone ? countryCode + " " + phone : "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { email }: any) => (
        <div>
          <span>{`${email || "-"}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage_users/userdetail/${record?._id}`);
            }}
            size="small"
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manageusers/edit/${record?._id}`);
            }}
            size="small"
          >
            <EditOutlined color="#08c" />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
            size="small"
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
  ];

  const getUserDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await userList({
        page: page,
        limit: 10,
        search: search || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setManageUserData(res?.data?.user);
        setTotalCount(res?.data?.userCount);
        console.log(res?.data?.user, "resList");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };
  console.log(totalCount, "count");

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (search == "") {
      getUserDetails(1);
    }
  }, [search]);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader
        heading="Manage Users"
        buttonNavigateTo="manageusers/add"
      />
      <div className="srch">
        <Card>
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getUserDetails(1)}
              >
                <Input
                  placeholder="Search Users"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 45, borderRadius: 10 }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 90,
                    height: 43,
                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
        <Table
          className="userTable"
          columns={columns}
          dataSource={manageUserData}
          pagination={pagination}
          rowKey="_id"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ManageUsers;
