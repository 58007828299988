import React from "react";
import { Button, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

interface interestPropTypes {
  interestDetails: {
    loanType: string;
    interestRate: string;
  };
}

const IntrestDetails = ({ interestDetails }: interestPropTypes) => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Interest Details</h2>
          <Button
            style={{
              background: "#0C163F",
              width: 100,
              height: 40,
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manageinterest", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Loan Type</h4>
              <h5>{interestDetails?.loanType}</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Interest Rate</h4>
              <h5>{interestDetails?.interestRate}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default IntrestDetails;
