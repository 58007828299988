import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../helpers";
import AuthWrapper from "../../../layout/authWrapper";
import { usePostResetPasswordMutation } from "../../../services/auth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [postResetPassword, PostRestPasswordData] =
    usePostResetPasswordMutation();

  const onFinish = async (values: any) => {
    const body = {
      password: values.password,
      key: "admin@yopmail.com",
    };

    try {
      const res = await postResetPassword(body).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        navigate("/", { replace: true });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </div>

        <h2>Reset Password</h2>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 460,
            marginTop: 40,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            style={{ width: 550 }}
            name="password"
            rules={[
              { required: true, message: "Please Enter password!" },
              // {
              //   pattern:
              //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //   message:
              //     "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
              // },
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px" }}
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              style={{ width: "460px", height: "50px", marginTop: 20 }}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#1e90ff",
                  width: 200,
                  height: 50,
                  margin: "auto",

                  marginTop: 10,
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={PostRestPasswordData.isLoading}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ResetPassword;
