import { API_URL, END_POINTS, STORAGE_KEYS, getData } from "../helpers";
import { IMAGE_UPLOAD } from "../helpers/constants/urls";

export const UploadMedia = async (imageObject: any) => {
  const formData = new FormData();

  formData.append("image", imageObject as any);

  const getToken = await getData(STORAGE_KEYS.token);
  const token = getToken ? `${getToken}` : null;
  let headers = {
    // "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(IMAGE_UPLOAD, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
