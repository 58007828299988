import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, Layout, MenuProps, Modal } from "antd";
import {
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { errorToast, getData, STORAGE_KEYS } from "../helpers";
import { clearData } from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { setCredentials } from "../reducers/authSlice";
import { useLazyGetProfileQuery } from "../services/auth";
import useAuth from "../hooks/useAuth";
import { User } from "../types/User";

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAuth();

  const [getProfile, getProfileData] = useLazyGetProfileQuery();
  const [profileDetail, setProfileDetail] = useState<User>();
  const [address, setAddress] = useState();

  const { confirm } = Modal;

  const showLogoutConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to logout?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        dispatch(
          setCredentials({
            token: null,
            user: null,
          })
        );
        clearData();
        navigate("/", { replace: true });
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Profile</span>,
      icon: <UserOutlined />,
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      label: <span>Change Password</span>,
      icon: <KeyOutlined />,
      onClick: () => navigate("/changePassword", { replace: true }),
    },
    {
      key: "3",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: showLogoutConfirm,
    },
  ];
  const { Header } = Layout;
  // const { Search } = Input;

  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        console.log(accounts);
        const account = accounts[0];
        setAddress(address);

        return account;
      } catch {
        console.log("ERROR");
      }
    } else {
      errorToast("MetaMask not found. Please install MetaMask extension.");
      throw new Error("MetaMask not found. Please install MetaMask extension.");
    }
  };

  const handleConnectMetamask = async () => {
    try {
      connectMetamask();
      if (address) {
        errorToast("MetaMask already connected");
      } else {
        connectMetamask();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileDetails = async (token: string) => {
    const res = await getProfile({}).unwrap();
    console.log(res, "get Profile");
    setProfileDetail(res?.data);
    dispatch(
      setCredentials({
        user: res?.data || null,
        token: token || null,
      })
    );
  };

  useEffect(() => {
    const token = getData(STORAGE_KEYS.token);
    const userData = getData(STORAGE_KEYS.userData);

    console.log(token, "toeknnnn");

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
    }
    getProfileDetails(`${token}`);
  }, []);

  return (
    <Header style={{ padding: 0 }}>
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
          padding: "10px 50px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {/* {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 450,
            justifyContent: "flex-end",
            gap: "8%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Search
              placeholder="input search text"
              className="inpt_hdr"
              // onSearch={onSearch}
              style={{ width: 200 }}
            /> */}
          </div>
          <button className="meta_btn" onClick={handleConnectMetamask}>
            <img src="/static/icons/MetaMask_Fox 1.png" />
          </button>
          <div
            style={{
              display: "flex",
            }}
          >
            <img
              style={{
                height: 50,
                width: 50,
                margin: "auto",
                borderRadius: "50%",
              }}
              src={userData?.profileImage}
              alt=""
            />
          </div>
          <div
            onClick={(e) => e.preventDefault()}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Dropdown menu={{ items }}>
              <div>
                <div style={{ height: 20 }}>
                  {userData?.fullName}
                  <CaretDownOutlined style={{ marginLeft: 10 }} />
                </div>
                <div style={{ paddingTop: 5 }}>Admin</div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderBar;
