import { useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import Queries from "./Queries";
import Complaints from "./Complaints";
import BookingQueries from "./BookingQueries";

const TransactionsHistory = () => {
  // const [activeTab, setActiveTab] = useState("1");

  // const onChange = (key: string) => {
  //   setActiveTab(key);
  // };

  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: `Queries Received`,
  //     children: <Queries />,
  //   },
  //   {
  //     key: "2",
  //     label: `Complaints/Disputes`,
  //     children: <Complaints />,
  //   },
  //   {
  //     key: "3",
  //     label: `Trade Related Disputes`,
  //     children: <BookingQueries />,
  //   },
  // ];
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Customer Support" />
      <Card>
        <Queries />
      </Card>
    </div>
  );
};

export default TransactionsHistory;
