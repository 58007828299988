import { Button, Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { ContentHeader } from "../../../components";
import { ABI, ContractAddress } from "../../../Web3_interact/configuration";

const TotalBalance = () => {
  const navigate = useNavigate();
  const [contract, setContract] = useState<any>();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  async function CheckloanFund() {
    try {
      setLoading(true);
      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();
      const user = accounts[0];
      console.log(contract.methods.CheckloanFund(), "OOOOOOO");
      // Estimate gas limit for the transaction
      // const gasLimit = await contract.methods.checkUsdtBalance().estimateGas({
      //   from: user,
      // });

      // console.log("Estimated gas limit:", gasLimit);

      // // Get the current gas price from the network
      // const gasPrice = await web3.eth.getGasPrice();
      // console.log("Current gas price:", gasPrice);

      // Send the transaction to the contract with gas options
      const result = await contract.methods.CheckloanFund().call({
        from: ContractAddress,
        // gas: gasLimit,
        // gasPrice: gasPrice,
      });
      setLoading(false);
      setResult(result);

      console.log("Total Balance", result);
    } catch (error) {
      console.error("ERROR", error);
    }
  }

  useEffect(() => {
    const web3 = new Web3(window.ethereum);
    const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
    console.warn(contractInstance);

    setContract(contractInstance);
    console.log(contract, "_______");
  }, []);

  useEffect(() => {
    if (contract) {
      CheckloanFund();
    } else {
      console.log("ERROR");
    }
  }, [contract]);

  return (
    <div style={{ padding: "40px 20px ", gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>total Balance</h2>
            <Button
              style={{
                background: "#1e90ff",
                width: 100,
                height: 40,
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/managedeposit", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Balance</h4>
                <h5>{Number(result) || "-"}</h5>
              </Col>
            </Row>

            {/* <Row gutter={16}>
          <Col xs={20} md={8}>
            <h4>Phone Number</h4>
            <h5>+91 2327136213</h5>
          </Col>
        </Row> */}
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default TotalBalance;
