import { useEffect, useState } from "react";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Modal, Row, Space, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { EyeOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import {
  useLazyGetAllLoanQuery,
  usePostApproveLoanMutation,
  useLazyRejectLoanQuery,
  useDeleteLoanMutation,
} from "../../services/loan";
import { ABI, ContractAddress } from "../../Web3_interact/configuration";
import { errorToast, successToast } from "../../helpers";
import { ContentHeader } from "../../components";

const { confirm } = Modal;

const ManageLoans = () => {
  const [getLoans] = useLazyGetAllLoanQuery();
  const [postApproveLoan] = usePostApproveLoanMutation();
  const [postRejectLoan] = useLazyRejectLoanQuery();
  const [deleteLoan] = useDeleteLoanMutation();
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allLoanData, setAllLoanData] = useState([]);
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState<any>();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  console.log(contract, "_______");

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    getAllLoanDetails(pages);
    console.log(pages, "page");
  };

  async function approveLoanFunc(record: any) {
    console.log(record?.loanType.toLowerCase(), "============");

    try {
      if (!record || !record.userAddress || !record.loanType) {
        console.log("Invalid loan record.");
      }

      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();
      const user = accounts[0];

      console.log("================_________", record?.userAddress);
      console.log("ADMIN ADDRESS ", user);
      // console.log(contract.methods.approveLoan(), "APPROVE LOAN");
      // Estimate gas limit for the transaction
      // const gasLimit = await contract.methods
      //   .approveLoan(record?.userAddress, record?.loanType.toLowerCase())
      //   .estimateGas({
      //     from: user,
      //   });

      // Get the current gas price from the network
      // const gasPrice = await web3.eth.getGasPrice();
      // console.log("Current gas price:", gasPrice);

      // Send the transaction to the contract with gas options
      const result = await contract.methods
        .approveLoan(record?.userAddress, record?.loanType.toLowerCase())
        .send({
          from: user,
          // gasPrice: gasPrice,
          gas: 2000000,
        });

      console.log("UserApproved", result);

      if (result) {
        await getEmiDetails(record);
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  async function getEmiDetails(record: any) {
    try {
      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();
      const user = accounts[0];
      console.log("=================================", user);

      // console.log(contract.methods.approveLoan(), "APPROVE LOAN");
      // Estimate gas limit for the transaction
      // const gasLimit = await contract.methods
      //   .getEmiDetails(record?.loanType.toLowerCase(), record?.userAddress)
      //   .estimateGas({
      //     from: user,
      //   });
      // console.log("Estimated gas limit:", gasLimit);

      // Send the transaction to the contract with gas options
      const result = await contract.methods
        .getEmiDetails(record?.loanType.toLowerCase(), record?.userAddress)
        .call({
          from: user,
          gas: 2000000,
        });

      console.log("EMI details send", result);
      console.log("NO OF EMI", result?.noOfEmiPending);

      if (result) {
        handleApprove(record?._id, result);
      }
    } catch (error) {
      console.error("Error", error);
    }
  }

  async function rejectLoan(record: any) {
    try {
      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();

      const user = accounts[0];
      console.log("user: ", user);
      // console.log(contract.methods.approveLoan(), "APPROVE LOAN");
      // Estimate gas limit for the transaction
      // const gasLimit = await contract.methods
      //   .rejectLoan(address, loanType.toLowerCase())
      //   .estimateGas({
      //     from: "0x03761FcaaCdf1bf880eB00b1f1Fc0ef21286099B",
      //   });

      // console.log("Estimated gas limit:", gasLimit);

      // Get the current gas price from the network
      // const gasPrice = await web3.eth.getGasPrice();
      // console.log("Current gas price:", gasPrice);

      // Send the transaction to the contract with gas options
      const result = await contract.methods
        .rejectLoan(record?.userAddress, record?.loanType.toLowerCase())
        .send({
          from: user,
          gas: 2000000,
          // gasPrice: gasPrice,
        });

      setResult(result);
      if (result) {
        handleReject(record?._id);
      }

      console.log("Loan Rejected", result);
    } catch (error) {
      console.error("Error", error);
    }
  }

  const handleApprove = async (id: string, data: any) => {
    try {
      let body = {
        EMI: parseInt(data?.EmiAmount),
        LoanAmountWithInterest: parseInt(data?.totalAmount),
        noOfEMI: parseInt(data?.noOfEmiPending),
        loanInterestamount: parseInt(data?.interestAmount),
      };

      const res = await postApproveLoan({ body, userId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        getAllLoanDetails(1);
        setCurrentPage(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleReject = async (id: string) => {
    console.log(id, "ID");
    try {
      const res = await postRejectLoan({
        userId: id,
      }).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        getAllLoanDetails(1);
        setCurrentPage(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const navigate = useNavigate();

  interface DataType {
    _id: string;
    key: string;
    name: string;
    email: string;
    loanAmount: string;
    status: string;
    coin: string;
    icon: any;
    symbol: string;
    loanStatus: string;
  }

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteLoan({ userId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("User Deleted Successfully");
        getAllLoanDetails(1);
        setCurrentPage(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this code?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        handleDelete(record?._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "20%",
      render: (_, { fullName }: any) => (
        <div>
          <span>{`${fullName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "10%",
      render: (_, { email }: any) => (
        <div>
          <span>{`${email || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Crypto Currency",
      dataIndex: "cryptoCurrency",
      key: "cryptoCurrency",
      align: "center",
      width: "20%",
      render: (_, { cryptoCurrencyType }: any) => (
        <div>
          <span>{`${cryptoCurrencyType || "-"}`}</span>
        </div>
      ),
      // render: (_, { coin, icon, symbol }) => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <div className="table_icon">{icon}</div>
      //     {/* <span>{symbol}</span> */}
      //     <span>{coin}</span>
      //   </div>
      // ),
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
      align: "center",
      width: "20%",
      render: (_, { loanAmount }) => (
        <div>
          <p>
            {loanAmount || "-"}
            <span style={{ color: "gray", paddingLeft: "5px" }}>USDT</span>
          </p>
        </div>
      ),
    },
    // {
    //   title: "collateral Amount",
    //   dataIndex: "collateral Amount",
    //   key: "collateral Amount",
    //   align: "center",
    //   width: "20%",
    //   render: (_, { loanAmount }) => (
    //     <div>
    //       <p>
    //         {loanAmount || "-"}
    //         <span style={{ color: "gray", paddingLeft: "5px" }}>ETH</span>
    //       </p>
    //     </div>
    //   ),
    // },
    {
      title: "Status ",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "20%",
      render: (_, { loanStatus }) => (
        <Tag
          color={
            loanStatus === "PENDING"
              ? "yellow"
              : loanStatus === "REJECTED"
              ? "error"
              : "success"
          }
        >
          {loanStatus}
        </Tag>
      ),
    },
    {
      title: "Request",
      dataIndex: "request",
      key: "request",
      align: "center",
      width: "20%",
      render: (_, record: any) => (
        <Space size="small">
          <Button
            onClick={(e) => {
              e.preventDefault();
              approveLoanFunc(record);
            }}
            size="small"
            disabled={
              record?.loanStatus === "REJECTED" ||
              record?.loanStatus === "APPROVED"
            }
          >
            <CheckOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              rejectLoan(record);
            }}
            size="small"
            disabled={
              record?.loanStatus === "REJECTED" ||
              record?.loanStatus === "APPROVED"
            }
          >
            <CloseOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <Space size="small">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managereferral/manageloandetail/${record?._id}`);
            }}
            size="small"
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
            size="small"
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getAllLoanDetails = async (page: number) => {
    setLoading(true);
    try {
      const res = await getLoans({
        page: page,
        limit: 10,
        search: search || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllLoanData(res?.data?.loan);
        setTotalCount(res?.data?.loanCount);
        console.log(res?.data, "resList___");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };
  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (search == "") {
      getAllLoanDetails(1);
    }
  }, [search]);

  useEffect(() => {
    const web3 = new Web3(window.ethereum);
    const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
    console.warn(contractInstance);
    setContract(contractInstance);
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Manage Loans" />
      <div className="srch">
        <Card>
          <Row gutter={16}>
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getAllLoanDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 45, borderRadius: 10 }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 90,
                    height: 43,
                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card>
          <div>
            <Table
              className="customTable"
              columns={columns}
              pagination={pagination}
              loading={loading}
              dataSource={allLoanData}
              rowKey="_id"
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ManageLoans;
