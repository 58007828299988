import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import { useState } from "react";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: string;
  role: string;
  roleEnabled: boolean;
}

const { confirm } = Modal;

const AddRole = () => {
  const [role, setrole] = useState("");
  const [editRole, seteditRole] = useState("");

  const renderSwitch = (text: any, record: any, index: any) => (
    <Switch defaultChecked={true} />
  );

  const showDeleteConfirm = () => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this role?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: "10%",
    },
    {
      title: "Role Enabled",
      dataIndex: "roleEnabled",
      key: "roleEnabled",
      render: renderSwitch,
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              seteditRole(record);
              setrole(record.role);
              // navigate(`/manageusers/edit/1`);
            }}
          >
            <EditOutlined color="#08c" />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm();
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      role: "Manager",
      roleEnabled: true,
    },
    {
      key: "2",
      role: "Financer",
      roleEnabled: true,
    },
    {
      key: "3",
      role: "Abcg",
      roleEnabled: true,
    },
  ];

  return (
    <div>
      <Form
        name="basic"
        style={{
          maxWidth: "80%",
        }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} md={16}>
            <Form.Item name="addRole">
              <div>
                <h4>Add Role</h4>
                <Input
                  value={role}
                  onChange={(e) => setrole(e.target.value)}
                  placeholder="Add Role"
                  style={{ height: 55 }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={12}>
            {editRole ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  style={{
                    background: "#0C163F",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "white",
                  }}
                  type="primary"
                >
                  Edit
                </Button>
                <Button
                  style={{
                    background: "#0C163F",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "white",
                  }}
                  type="primary"
                  onClick={() => {
                    setrole("");
                    seteditRole("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                style={{
                  background: "#0C163F",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  color: "white",
                }}
                type="primary"
                disabled={role === ""}
              >
                Add
              </Button>
            )}
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              style={{ marginTop: 20 }}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddRole;
