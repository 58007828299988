import "./App.css";
import { useEffect } from "react";
import RoutesNavigation from "./routes";
import { STORAGE_KEYS, getData } from "./helpers";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getData(STORAGE_KEYS.token);

    if (token && window?.location?.pathname === "/") {
      window?.location?.replace("/dashboard");
    } else if (!token && window?.location?.pathname === "/") {
      navigate("/", { replace: true });
    } else if (token) {
      navigate(window?.location?.pathname);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  return <RoutesNavigation />;
}

export default App;
