import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetTransactionByIdQuery } from "../../../services/users";
import TransactionDetails from "../../../components/manageTransactions/TransactionDetails";
import { serialize } from "v8";

const LoanDetails = () => {
  const { id } = useParams();
  const [getTransactionById] = useLazyGetTransactionByIdQuery();

  const [loanData, setLoanData] = useState<any>();
  const [transactions, setTransactions] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getLoanDetails = async () => {
    setLoading(true);
    try {
      const res = await getTransactionById(id).unwrap();
      setLoading(false);
      console.log(res, "getData");

      if (res?.statusCode === 200) {
        setLoanData(res?.data[0]);
        setTransactions(res?.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getLoanDetails();
  }, []);

  return (
    <TransactionDetails
      loanData={loanData}
      transactions={transactions}
      loading={loading}
    />
  );
};

export default LoanDetails;
