import { useEffect, useState } from "react";
import {
  UserOutlined,
  DollarOutlined,
  PieChartOutlined,
  LineChartOutlined,
  SettingOutlined,
  FormOutlined,
  SwitcherOutlined,
  PercentageOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./layout.css";
import { useNavigate, useLocation } from "react-router-dom";

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState("1");

  useEffect(() => {
    console.log(location.pathname);
    if (location?.pathname?.includes("dashboard")) {
      setSelectedKey("1");
    } else if (location?.pathname?.includes("manageusers")) {
      setSelectedKey("2");
    } else if (location?.pathname?.includes("revenue")) {
      setSelectedKey("3");
    } else if (location?.pathname?.includes("managereferral")) {
      setSelectedKey("4");
    } else if (location?.pathname?.includes("managecurrency")) {
      setSelectedKey("5");
    } else if (location?.pathname?.includes("transactions")) {
      setSelectedKey("6");
    } else if (location?.pathname?.includes("analytics")) {
      setSelectedKey("7");
    } else if (location?.pathname?.includes("managecms")) {
      setSelectedKey("8");
    } else if (location?.pathname?.includes("managefaq")) {
      setSelectedKey("9");
    } else if (location?.pathname?.includes("settings")) {
      setSelectedKey("10");
    } else {
      setSelectedKey("");
    }
  }, [location]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img
          src={
            collapsed
              ? "/static/images/crypto_logo.png"
              : "/static/images/crypto_logo.png"
          }
          className={collapsed ? "logo_img_small" : "logo_img"}
          alt=""
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className="side_menu"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={[
          {
            key: "1",
            icon: <PieChartOutlined />,
            label: "Dashboard",
            onClick: () => navigate("/dashboard"),
          },

          {
            key: "2",
            icon: <UserOutlined />,
            label: "Manage Users",
            onClick: () => navigate("/manage_users"),
          },
          {
            key: "3",
            icon: <DollarOutlined />,
            label: "Manage Funds",
            onClick: () => navigate("/managedeposit"),
          },
          {
            key: "4",
            icon: <DollarOutlined />,
            label: "Manage Loans",
            onClick: () => navigate("/manageloans"),
          },
          {
            key: "5",
            icon: <SnippetsOutlined />,
            label: "Transactions",
            onClick: () => navigate("/managecurrency"),
          },
          {
            key: "6",
            icon: <PercentageOutlined />,
            label: "Manage Interest",
            onClick: () => navigate("/manageinterest"),
          },
          {
            key: "7",
            icon: <LineChartOutlined />,
            label: "Analytics",
            onClick: () => navigate("/analytics"),
          },
          {
            key: "8",
            icon: <FormOutlined />,
            label: "Manage CMS",
            onClick: () => navigate("/managecms"),
          },
          {
            key: "9",
            icon: <SwitcherOutlined />,
            label: "Manage FAQ's",
            onClick: () => navigate("/managefaq"),
          },
          {
            key: "10",
            icon: <SettingOutlined />,
            label: "Settings",
            onClick: () => navigate("/settings"),
          },
        ]}
      />
    </Sider>
  );
};

export default SiderBar;
