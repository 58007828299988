import { Button, Card, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import { successToast } from "../../helpers";
import {
  useLazyGetAllFaqsQuery,
  useAddFaqsMutation,
  useDeleteFaqMutation,
} from "../../services/faqs";
import { Faqs } from "../../types/General";

const ManageFaq = () => {
  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [postAddFaq, postAddFaqMutation] = useAddFaqsMutation();
  const [getAllFaq, getAllFaqData] = useLazyGetAllFaqsQuery();
  const [deleteFaqMutation] = useDeleteFaqMutation();

  const getAllFaqs = async () => {
    try {
      const res = await getAllFaq({}).unwrap();
      console.log(res, "getall");

      if (res?.statusCode === 200) {
        setFaqs(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
    console.log(faqs, "faqssss");
  };

  const handleAddFaq = async () => {
    let body = {
      question: question,
      answer: answer,
    };
    try {
      const res = await postAddFaq(body).unwrap();
      console.log(res, "add");

      if (res?.statusCode === 200) {
        successToast("Added Successfully");
        getAllFaqs();
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const removeFaqsHandler = async (id: string | undefined) => {
    try {
      const res = await deleteFaqMutation({ faq_id: id }).unwrap();

      if (res?.statusCode === 200) {
        getAllFaqs();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Manage FAQ" />
      <Card>
        <Row gutter={4}>
          <Col xs={16} md={20}>
            <h3>FAQs</h3>

            {faqs?.length
              ? faqs?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          gap: "20px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <div>
                            <h4>Question:</h4>
                            <Input
                              disabled={!!item?.question}
                              type={"text"}
                              name="name"
                              value={item?.question}
                              placeholder="Question"
                              style={{
                                height: 55,
                                marginLeft: 20,
                                color: "black",
                              }}
                            ></Input>
                          </div>
                          <div style={{}}>
                            <h4>Answer:</h4>
                            <Input
                              disabled={!!item?.answer}
                              type={"text"}
                              name="name"
                              value={item?.answer}
                              placeholder="Answer"
                              style={{
                                height: 55,
                                marginLeft: 20,
                                color: "black",
                              }}
                            ></Input>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                          }}
                        >
                          <Button onClick={() => removeFaqsHandler(item._id)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div>
                  <h4>Question:</h4>
                  <Input
                    type={"text"}
                    name="name"
                    value={question}
                    onChange={(val) => setQuestion(val.target.value)}
                    placeholder="Question"
                    style={{ height: 55, marginLeft: 20 }}
                  ></Input>
                </div>
                <div>
                  <h4>Answer:</h4>
                  <Input
                    type={"text"}
                    name="name"
                    value={answer}
                    onChange={(val) => setAnswer(val.target.value)}
                    placeholder="Answer"
                    style={{
                      height: 55,
                      marginLeft: 20,
                    }}
                  ></Input>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                // style={{ margin: 2, marginTop: 20 }}
                style={{
                  background: "#1e90ff",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  marginTop: 20,
                  alignSelf: "center",
                  color: "white",
                }}
                type="primary"
                onClick={handleAddFaq}
                disabled={!question || !answer}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManageFaq;
