import { Button, Card, Col, Row, Table } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

interface DataType {
  id: number;
  label: string;
  view: boolean;
  edit: boolean;
}

const SubAdminDetails = () => {
  const navigate = useNavigate();

  const data: DataType[] = [
    {
      id: 1,
      label: "Manage Users",
      view: true,
      edit: false,
    },
    {
      id: 2,
      label: "Notification",
      view: false,
      edit: true,
    },
    {
      id: 3,
      label: "Manage CMS",
      view: true,
      edit: false,
    },
    {
      id: 4,
      label: "Manage Leaderboard",
      view: true,
      edit: true,
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Add/Edit",
      dataIndex: "",
      key: "",
      render: ({ edit, key }: any) => {
        return (
          <div>
            {edit ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      key: "",
      render: ({ view, key }: any) => {
        return (
          <div>
            {view ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Sub-Admin Details</h2>
          <Button
            style={{
              background: "#0C163F",
              width: 100,
              height: 40,
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managesubadmins", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h4>Image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    height: 100,
                    // margin: "auto",
                  }}
                  src="/static/images/profile.png"
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Full Name</h4>
              <h5>John Brown</h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>johnbrown10@gmail.com</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>+91 2327136213</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Address</h4>
              <h5>C-127, phase-8, 3rd floor</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Role</h4>
              <h5>Manager</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                style={{ marginTop: 20, marginBottom: 20 }}
                pagination={false}
                dataSource={data}
                columns={columns}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default SubAdminDetails;
