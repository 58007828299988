import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  ManageLoans,
  ManageUsers,
  Notifications,
  Settings,
  ManageCms,
  ManageFaq,
  ManageSubAdmins,
  Profile,
  SubAdminDetails,
  SubAdminForm,
  UsersForm,
  UserDetails,
  ManageInterest,
  Analytics,
  ManageDeposit,
  CustomerSupport,
  ManageCurrency,
  ManageDepositForm,
  ManageIntrestForm,
  LoanDetails,
  ManageLoanDetail,
  ManageInterestDetail,
  DepositDetails,
  TotalBalance,
} from "../pages";
import {
  ForgetPassword,
  Login,
  Otp,
  ChangePassword,
  ResetPassword,
} from "../pages/auth";
import MainLayout from "../layout";
import NotificationsHistory from "../pages/notifications/details";

const RoutesNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/forgotpassword" element={<ForgetPassword />}></Route>
      <Route path="/otp" element={<Otp />}></Route>
      <Route path="/resetpassword" element={<ResetPassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage_users"
        element={
          <MainLayout>
            <ManageUsers />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageusers/edit/:id"
        element={
          <MainLayout>
            <UsersForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage_users/userdetail/:id"
        element={
          <MainLayout>
            <UserDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins"
        element={
          <MainLayout>
            <ManageSubAdmins />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>
      <Route path="/changePassword" element={<ChangePassword />}></Route>
      <Route
        path="/managecms"
        element={
          <MainLayout>
            <ManageCms />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/notifications/history"
        element={
          <MainLayout>
            <NotificationsHistory />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageloans"
        element={
          <MainLayout>
            <ManageLoans />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managereferral/manageloandetail/:id"
        element={
          <MainLayout>
            <ManageLoanDetail />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managefaq"
        element={
          <MainLayout>
            <ManageFaq />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manageinterest"
        element={
          <MainLayout>
            <ManageInterest />
          </MainLayout>
        }
      ></Route>
      <Route
        path={"/manageinterest/intrestform/:id"}
        element={
          <MainLayout>
            <ManageIntrestForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manageinterest/intrestdetail/:id"
        element={
          <MainLayout>
            <ManageInterestDetail />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/analytics"
        element={
          <MainLayout>
            <Analytics />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managedeposit"
        element={
          <MainLayout>
            <ManageDeposit />
          </MainLayout>
        }
      ></Route>

      <Route
        path={"/managedeposit/depositform/:view"}
        element={
          <MainLayout>
            <ManageDepositForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managedeposit/totalBalance/:view"
        element={
          <MainLayout>
            <TotalBalance />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/edit/:id`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path={`/managesubadmins/add`}
        element={
          <MainLayout>
            <SubAdminForm />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managesubadmins/subadmindetail/:id"
        element={
          <MainLayout>
            <SubAdminDetails />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/customersupport"
        element={
          <MainLayout>
            <CustomerSupport />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managecurrency"
        element={
          <MainLayout>
            <ManageCurrency />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/managecurrency/transactiondetail/:id"
        element={
          <MainLayout>
            <LoanDetails />
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesNavigation;
