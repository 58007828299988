import React, { useEffect, useState } from "react";

import DepositForm from "../../../components/manageDeposit/DepositForm";
import { successToast } from "../../../helpers";
import { useAddDepositMutation } from "../../../services/loan";
import Web3, { Contract } from "web3";
import { ABI, ContractAddress } from "../../../Web3_interact/configuration";

const ManageDepositForm = () => {
  const [addDeposit, addDepositMutation] = useAddDepositMutation();

  const [deposit, setDeposit] = useState("");
  const [CurrencyType, setCurrencyType] = useState("USDT");
  const [adminAddress, setAdminAddress] = useState("");
  const [bankAddress, setBankAddress] = useState("");

  const [contract, setContract] = useState<any>();

  // Example of adding a contract instance to the array

  async function addLoanFund() {
    try {
      const web3 = new Web3(window.ethereum);
      // Get the user's account address
      const accounts = await web3.eth.getAccounts();
      const user = accounts[0];

      // Estimate gas limit for the transaction
      const gasLimit = await contract.methods
        .addLoanFund(parseInt(deposit))
        .estimateGas({
          from: user,
          value: parseInt(deposit),
        });

      console.log("Estimated gas limit:", gasLimit);

      // Get the current gas price from the network
      const gasPrice = await web3.eth.getGasPrice();
      console.log("Current gas price:", gasPrice);

      // Send the transaction to the contract with gas options
      const result = await contract.methods
        .addLoanFund(parseInt(deposit))
        .send({
          from: user,
          value: parseInt(deposit),
          gas: gasLimit,
          gasPrice: gasPrice,
        });

      if (result) {
        handleAddDeposit();
      }

      console.log("Fund Added Successfully", result);
    } catch (error) {
      console.error("Error", error);
    }
  }

  const handleAddDeposit = async () => {
    let body = {
      deposit: parseInt(deposit),
      CurrencyType: CurrencyType,
      adminAddress: adminAddress,
      bankAddress: bankAddress,
    };
    try {
      const res = await addDeposit(body).unwrap();
      console.log(res, "add");

      if (res?.statusCode === 200) {
        successToast(res?.message);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const web3 = new Web3(window.ethereum);
    const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
    console.log(contractInstance);

    setContract(contractInstance);
  }, []);
  return (
    <div style={{ padding: "20px 20px" }}>
      <DepositForm
        onClick={addLoanFund}
        setDeposit={setDeposit}
        setCurrencyType={setCurrencyType}
      />
    </div>
  );
};

export default ManageDepositForm;
