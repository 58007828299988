import { Breadcrumb, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";

type ContentHeaderType = {
  heading: string;
  buttonText?: string;
  buttonNavigateTo?: string;
};

const ContentHeader = ({
  heading,
  buttonText,
  buttonNavigateTo,
}: ContentHeaderType) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          margin: "30px 0px",
        }}
      >
        <Breadcrumb
          items={[
            {
              title: (
                <div
                  className="breadcrumb_item"
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </div>
              ),
            },
            {
              title: <div className="breadcrumb_item">{heading}</div>,
            },
          ]}
        />
      </div>

      <Card
        style={{
          margin: "20px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {heading}
          </div>
          {buttonText ? (
            <Button
              style={{
                background: "#1e90ff",
                minWidth: 100,
                height: 40,
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                if (buttonNavigateTo) {
                  navigate(`/${buttonNavigateTo}`);
                } else {
                  navigate("/dashboard");
                }
              }}
            >
              {buttonText || "Add"}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ContentHeader;
