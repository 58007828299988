import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Input, Modal, Row, Space, Switch } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import {
  useLazyGetInterestQuery,
  useDeleteLoanTypeMutation,
  useLazyActiveInactiveInterestQuery,
} from "../../services/loan";
import { errorToast, successToast } from "../../helpers";
import { ContentHeader } from "../../components";

const { confirm } = Modal;

const CrytoTransactionsHistory = () => {
  const [getInterest, getInterestData] = useLazyGetInterestQuery();
  const [deleteLoanType] = useDeleteLoanTypeMutation();
  const [activeInterest, activeInterestMutation] =
    useLazyActiveInactiveInterestQuery();
  const [allLoanType, setAllLoanType] = useState<any>();
  const navigate = useNavigate();

  interface DataType {
    _id: string;
    key: string;
    intrest: string;
    status: boolean;
    platForm: string;
  }

  const activeInactiveInterest = async (isActive: boolean, _id: string) => {
    try {
      const res = await activeInterest({ userId: _id }).unwrap();

      if (res?.statusCode === 200) {
        console.log(_id, "ID");
        getInterestDetails();

        successToast(
          !isActive
            ? "Interest Deactivated Successfully!"
            : "Interest Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Loan Type",
      dataIndex: "loanType",
      key: "loanType",
      align: "center",
      render: (_, { loanType }: any) => (
        <div>
          <span>{`${loanType || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Interest rate",
      key: "interestRate",
      dataIndex: "interestRate",
      align: "center",
      width: "20%",
      render: (_, { interestRate }: any) => (
        <div>
          <span>{`${interestRate || "-"} %`}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "points",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manageinterest/intrestdetail/${record?._id}`);
            }}
            size="small"
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manageinterest/intrestform/${record?._id}`);
            }}
            size="small"
          >
            <EditOutlined color="#08c" />
          </Button>
          {/* <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
            size="small"
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button> */}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "deviation",
      align: "center",
      render: (_, { isActive, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={isActive}
          onChange={() => activeInactiveInterest(!isActive, _id)}
        />
      ),
    },
  ];

  const getInterestDetails = async () => {
    try {
      const res = await getInterest({}).unwrap();

      if (res?.statusCode === 200) {
        setAllLoanType(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInterestDetails();
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader
        heading="Manage Interest"
        // buttonText="Add Interest "
        // buttonNavigateTo="manageinterest/addinterest"
      />
      <Card>
        <div className="srch">
          {/* <Row style={{ paddingTop: 20, paddingBottom: 20 }} gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 55, borderRadius: 10 }}
              />
            </Col>
          </Row> */}

          <Table
            columns={columns}
            dataSource={allLoanType}
            pagination={false}
            rowKey="_id"
          />
        </div>
      </Card>
    </div>
  );
};

export default CrytoTransactionsHistory;
