import { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Upload, { RcFile } from "antd/es/upload";

import { UploadMedia } from "../../utils/mediaUpload";
import {
  usePutUpdateProfileMutation,
  useLazyGetProfileQuery,
} from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { errorToast, getData, STORAGE_KEYS, successToast } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";

const Profile = () => {
  const dispatch = useAppDispatch();
  const [updateProfile, updateProfileData] = usePutUpdateProfileMutation();
  const [getProfile, getProfileData] = useLazyGetProfileQuery();

  const userData = useAuth();
  console.log(userData, "Dataa");

  // const [profileImage, setProfileImage] = useState(null);
  const [countryName, setCountryName] = useState("in");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    let body = {
      fullName: name,
      email: email,
      countryCode: countryCode,
      phone: phoneNo,
      profileImage: profileImage,
    };
    console.log(body, "body");

    try {
      const res = await updateProfile(body).unwrap();
      console.log(res, "89898989");

      if (res?.statusCode === 200) {
        const getRes = await getProfile({}).unwrap();
        if (getRes?.statusCode === 200) {
          const token = getData(STORAGE_KEYS.token);

          successToast("Profile updated successfully");

          console.log(token, "tokennn");

          dispatch(
            setCredentials({
              user: getRes?.data || null,
              token: token ? `${token}` : null,
            })
          );
        }
        navigate("/dashboard", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    console.log(file, "---------");
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        console.log(res, "Image");
        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const handleChangePhone = (phone: any, country: any) => {
    setCountryCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        email: userData?.email,
        fullName: userData?.fullName,
        address: userData?.address,
        phone: userData?.phone,
        username: userData?.username,
      });
      setName(userData?.fullName || "");
      setPhoneNo(userData?.phone || "");
      setCountryCode(userData?.countryCode || "+91");
      setEmail(userData?.email || "");
      setProfileImage(userData?.profileImage || "");
    }
  }, [userData, form]);

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>User Profile</h2>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 50,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="image">
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Full Name</h4>
                    <Input
                      value={name}
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      value={email}
                      // disabled
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={countryCode + phoneNo}
                        enableSearch={true}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}></Col>
            </Row>

            <Row gutter={16}></Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#1e90ff",
                    width: 200,
                    height: 50,
                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
function setPhoneCode(dialCode: any) {
  throw new Error("Function not implemented.");
}
