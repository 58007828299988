import React, { useEffect, useState } from "react";
import { Layout, Col, Row } from "antd";
import Web3 from "web3";

import { DashboardCard } from "../../components";
import RevenueChart from "../../components/charts/RevenueChart";
import UsersChart from "../../components/charts/UsersChart";
import { useLazyGetDashboardQuery } from "../../services/auth";
import { DashboardTypes } from "../../types/General";

import "./dashboard.css";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const Dashboard = () => {
  const { Content } = Layout;

  const [getDashboard] = useLazyGetDashboardQuery();
  const [dashboardDetails, setDashboardDetails] = useState<DashboardTypes>();
  const [name, setName] = useState<string | undefined>();
  const [selectedFilter, setSelectedFilter] = useState("monthly");
  const [selectedFilterUser, setSelectedFilterUser] = useState("monthly");
  const [revenueGraphValues, setRevenueGraphValues] = useState<string[]>([]);
  const [graphValues, setGraphValues] = useState<string[]>([]);

  const [web3, setWeb3] = useState<Web3 | null>(null);

  const handleFilterChange = (value: any) => {
    setSelectedFilter(value);
  };
  const handleFilterChangeUser = (value: any) => {
    setSelectedFilterUser(value);
  };

  const showDataHandler = async () => {
    try {
      const payload = await getDashboard({
        user: selectedFilterUser,
        revenue: selectedFilter,
      }).unwrap();
      console.log(payload?.data, "GET DASHBOARD");
      setDashboardDetails(payload?.data);

      const details = payload?.data;
      if (payload?.statusCode === 200) {
        if (name === "left") {
          // setRevenueNames(details?.revenueData);
          setRevenueGraphValues(details?.revenueData);
        } else if (name === "right") {
          // setNames(details?.userData);
          setGraphValues(details?.userData);
        } else {
          // setNames(details?.userData);
          setGraphValues(details?.userData);

          setRevenueGraphValues(details?.revenueData);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const initializeWeb3 = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        setWeb3(web3Instance);
      } catch (error) {
        console.error("Failed to connect to MetaMask:", error);
      }
    } else {
      console.error("MetaMask not found");
    }
  };

  useEffect(() => {
    showDataHandler();
  }, [selectedFilter]);

  useEffect(() => {
    showDataHandler();
  }, [selectedFilterUser]);

  useEffect(() => {
    initializeWeb3();
  }, []);

  const STATS = [
    {
      name: "Total Earnings",
      icon: "/static/icons/dollar-symbol.png",
      number: dashboardDetails?.totalEarning[0]?.total || "-",
      prefix: "$",
    },
    {
      name: "Total Users",
      icon: "/static/icons/group.png",
      number: dashboardDetails?.totalUser || "-",
      prefix: "#",
    },
    {
      name: "Total Ethereum",
      icon: "/static/icons/ethereum.png",
      number: dashboardDetails?.totalEthereum[0]?.total || "-",
      prefix: "#",
    },
    {
      name: "Total Borrowers",
      icon: "/static/icons/group.png",
      number: dashboardDetails?.totalBorrowers || "-",
      prefix: "#",
    },
  ];

  const labels: never[] = [];

  const labels2: never[] = [];

  const graphData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenueGraphValues,
        borderColor: "#1e90ff",
        backgroundColor: "#1e90ff",
        cubicInterpolationMode: "monotone",
        fill: true,
      },
    ],
  };

  const graphCustomerData = {
    labels2,
    datasets: [
      {
        label: "Users",
        data: graphValues,
        borderColor: "#1e90ff",
        backgroundColor: "#1e90ff",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  return (
    <Content
      style={{
        padding: 20,
        minHeight: 280,
      }}
    >
      <div style={{ fontSize: 20 }}>
        <h1>Dashboard</h1>
      </div>

      <div className="top_bar">
        {STATS.map((item, index) => (
          <DashboardCard data={item} key={index} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "30px 0px",
          width: "100%",
        }}
      ></div>
      <div>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <RevenueChart
              graphData={graphData}
              onFilterChange={handleFilterChange}
              setName={setName}
            />
          </Col>
          <Col xs={24} md={12}>
            <UsersChart
              graphCustomerData={graphCustomerData}
              onFilterChange={handleFilterChangeUser}
              setName={setName}
            />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default Dashboard;
