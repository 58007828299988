import { useEffect, useState } from "react";
import { Button, Card, Modal, Space, Table } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";

import { ContentHeader } from "../../components";
import { useLazyGetTransactionsQuery } from "../../services/users";

const ManageCurrency = () => {
  const navigate = useNavigate();
  const [getTransactions, getTransactionsData] = useLazyGetTransactionsQuery();
  const [allTransactions, setAllTransactions] = useState([]);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  interface DataType {
    key: string;
    name: string;
    email: string;
    loanAmount: string;
    startDate: string;
    dueDate: string;
    loanApprovedDate: string;
    emiDueDate: string;
    userId: string;
  }

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    getTransactionsDetails(pages);
    console.log(pages, "page");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
      render: (_, { fullName }: any) => (
        <div>
          <span>{`${fullName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Loan amount",
      dataIndex: "loanAmount",
      key: "address",
      align: "center",
      render: (_, { loanAmount }) => (
        <div>
          <p>
            {loanAmount}
            <span style={{ color: "gray", paddingLeft: "5px" }}>USDT</span>
          </p>
        </div>
      ),
    },
    {
      title: "Start date",
      dataIndex: "loanApprovedDate",
      key: "loanApprovedDate",
      align: "center",
      render: (_, { loanApprovedDate }) => (
        <div>
          <span>
            {loanApprovedDate
              ? `${moment(loanApprovedDate).utc().format("ll")}`
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
      align: "center",
      render: (_, { emiDueDate }) => (
        <div>
          <span>
            {emiDueDate ? `${moment(emiDueDate).utc().format("ll")}` : "-"}
          </span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/managecurrency/transactiondetail/${record?.userId}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getTransactionsDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getTransactions({
        page: page,
        limit: 10,
        search: search || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllTransactions(res?.data?.transaction);
        setTotalCount(res?.data?.transactionCount);
        console.log(res?.data?.transaction?.userId, "resList______");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (search == "") {
      getTransactionsDetails(1);
    }
  }, [search]);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Manage Transactions" />
      <Card>
        {/* <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        /> */}
        <Table
          className="customTable"
          columns={columns}
          pagination={pagination}
          dataSource={allTransactions}
          loading={loading}
          rowKey="_id"
        />
      </Card>
    </div>
  );
};

export default ManageCurrency;
