import emptySplitApi from "../utils/rtk";
import type { User } from "../types/User";
import { END_POINTS } from "../helpers";

type GetLoanResponse = {
  statusCode: number;
  data: any;
  message: string;
};

type GetLoanByIdResponse = {
  statusCode: number;
  data: any;
  message: string;
};

// type GetUserByIdResponse = {
//   statusCode: number;
//   data: User;
//   message: string;
// };

type GetLoanParams = {
  limit: number;
  page: number;
  search?: string | undefined;
};

type ApproveLoanParams = {
  userId: string;
  body: any;
};

type GetDepositBody = {
  deposit: number;
  CurrencyType: string;
  adminAddress: string;
  bankAddress: string;
};

type AddLoanBody = {
  loanType: string;
  interestRate: number | undefined;
};

export const loanApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLoan: builder.query<GetLoanResponse, GetLoanParams>({
      query: ({ page, limit, search }) => ({
        url:
          END_POINTS.getAllLoan +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    getLoanById: builder.query<GetLoanByIdResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.getLoanById}/${userId}`,
        method: "GET",
      }),
    }),

    postApproveLoan: builder.mutation<GetLoanResponse, ApproveLoanParams>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.approveLoan}/${userId}`,
        method: "POST",
        body,
      }),
    }),

    postRejectLoan: builder.mutation<GetLoanResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.rejectLoan}/${userId}`,
        method: "POST",
      }),
    }),

    deleteLoan: builder.mutation<GetLoanResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteLoan}/${userId}`,
        method: "DELETE",
      }),
    }),

    getAllDeposit: builder.query<GetLoanResponse, GetLoanParams>({
      query: ({ page, limit, search }) => ({
        url:
          END_POINTS.getAllfund +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    addDeposit: builder.mutation<GetLoanResponse, GetDepositBody>({
      query: (body) => ({
        url: END_POINTS?.addfund,
        method: "POST",
        body,
      }),
    }),

    getInterest: builder.query<GetLoanResponse, {}>({
      query: () => ({
        url: END_POINTS.getLoanType,
        method: "GET",
      }),
    }),

    getInterestById: builder.query<GetLoanByIdResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.getLoanTypeById}/${userId}`,
        method: "GET",
      }),
    }),

    deleteLoanType: builder.mutation<GetLoanResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteLoanType}/${userId}`,
        method: "DELETE",
      }),
    }),

    putUpdateLoanType: builder.mutation<
      { statusCode: number; message: string },
      { userId: string; body: any }
    >({
      query: ({ body, userId }: any) => ({
        url: `${END_POINTS.updateLoanType}/${userId}`,
        method: "PUT",
        body,
      }),
    }),

    logOutUserById: builder.mutation<GetLoanResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.logoutUserById}/${userId}`,
        method: "POST",
      }),
    }),

    activeInactiveInterest: builder.query<
      { statusCode: number; message: string },
      {}
    >({
      query: ({ userId }: any) => ({
        url: `${END_POINTS.setActiveOrInactive}/${userId}`,
        method: "GET",
      }),
    }),

    rejectLoan: builder.query<GetLoanResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.rejectLoan}/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllLoanQuery,
  useLazyGetLoanByIdQuery,
  usePostApproveLoanMutation,
  useDeleteLoanMutation,
  useLazyGetAllDepositQuery,
  useAddDepositMutation,
  useLazyGetInterestQuery,
  useLazyGetInterestByIdQuery,
  useDeleteLoanTypeMutation,
  usePutUpdateLoanTypeMutation,
  useLogOutUserByIdMutation,
  useLazyActiveInactiveInterestQuery,
  useLazyRejectLoanQuery,
} = loanApi;
