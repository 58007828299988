import emptySplitApi from "../utils/rtk";
import type { User } from "../types/User";
import { END_POINTS } from "../helpers";

type GetUsersResponse = {
  statusCode: number;
  data: any;
  message: string;
};

type GetUserByIdResponse = {
  statusCode: number;
  data: any;
  message: string;
};

type PostBlockUserParams = {
  userId: string;
};

type GetUsersParams = {
  limit: number;
  page: number;
  search?: string | undefined;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponse, GetUsersParams>({
      query: ({ page, limit, search }) => ({
        url:
          END_POINTS.getUserList +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    getUserById: builder.query<GetUserByIdResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.getUserById}/${userId}`,
        method: "GET",
      }),
    }),

    deleteUser: builder.mutation<GetUserByIdResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteUser}/${userId}`,
        method: "DELETE",
      }),
    }),

    putUpdateUser: builder.mutation<
      { statusCode: number; message: string },
      {}
    >({
      query: ({ body, userId }: any) => ({
        url: `${END_POINTS.editUser}/${userId}`,
        method: "PUT",
        body,
      }),
    }),

    postBlockUser: builder.query<GetUsersResponse, PostBlockUserParams>({
      query: ({ userId }) => ({
        url: `${END_POINTS.blockUnblockUser}/${userId}`,
        method: "GET",
      }),
    }),

    getTransactions: builder.query<GetUsersResponse, GetUsersParams>({
      query: ({ page, limit, search }) => ({
        url:
          END_POINTS.getAllTransaction +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    getTransactionById: builder.query<GetUserByIdResponse, string | undefined>({
      query: (userId) => ({
        url: `${END_POINTS.getTransactionById}/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useLazyGetUserByIdQuery,
  useDeleteUserMutation,
  usePutUpdateUserMutation,
  useLazyPostBlockUserQuery,
  useLazyGetTransactionsQuery,
  useLazyGetTransactionByIdQuery,
} = authApi;
