import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostForgetPasswordMutation } from "../../../services/auth";
import { errorToast, successToast } from "../../../helpers";

import {
  usePostVerifyOtpMutation,
  usePostSendOtpMutation,
} from "../../../services/auth";

const Otp = () => {
  const [postVerifyOtp, postVerifyOtpData] = usePostVerifyOtpMutation();
  const [postSendOtp, postSendOtpData] = usePostSendOtpMutation();

  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(59);
  const location = useLocation();
  const { state } = location;

  const handleOtpChange = (value: React.SetStateAction<string>) => {
    setOtp(value);
  };

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const body = {
      key: state.key,
      otp: otp,
    };

    try {
      const res = await postVerifyOtp(body).unwrap();

      if (res?.statusCode === 200) {
        successToast("Otp verified successfully");
        navigate("/resetpassword", { replace: true });
        console.log(res, "response");
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const sendOtp = async () => {
    const body = {
      key: state.key,
    };

    try {
      const res = await postSendOtp(body).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        // navigate("/resetpassword", { replace: true });
        console.log(res, "response");
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </div>
        {/* <div className="login_img">
          <img src="/static/images/crypto_logo.png" alt="" />
        </div> */}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{
            maxWidth: 600,
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            // label="Username"
            name="otp"
            //   rules={[{ required: true, message: "Please input your email!" }]}
          >
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={4}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                border: "none",
                borderBottom: "1px solid black",
                margin: 10,
                padding: 15,
              }}
              containerStyle={{ padding: 20 }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  backgroundColor: "#1e90ff",
                  width: 200,
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={postVerifyOtpData?.isLoading}
              >
                Verify
              </Button>
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            {countDown === 0 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    background: "#ffffff",
                    width: 160,
                    height: 40,
                    margin: "auto",
                  }}
                  type="text"
                  className="resendBtn"
                  loading={postSendOtpData?.isLoading}
                  onClick={sendOtp}
                >
                  Resend
                </Button>
              </div>
            ) : (
              <h4>
                {" "}
                OTP will expire in{" "}
                <span className="count"> 00 : {countDown}</span>{" "}
              </h4>
            )}
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Otp;
