import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";

type PostAddFaqsResponse = {
  statusCode: number;
  message: string;
};

type PostAddFaqsBody = {
  question: string;
  answer: string;
};

type GetAllFaqsResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

export const faqApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addFaqs: builder.mutation<PostAddFaqsResponse, PostAddFaqsBody>({
      query: (body) => ({
        url: END_POINTS.addFaq,
        method: "POST",
        body,
      }),
    }),

    getAllFaqs: builder.query<GetAllFaqsResponse, {}>({
      query: () => ({
        url: END_POINTS.getFaq,
        method: "GET",
      }),
    }),

    deleteFaq: builder.mutation<
      PostAddFaqsResponse,
      { faq_id: string | undefined }
    >({
      query: ({ faq_id }) => ({
        url: `${END_POINTS.deleteFaq}/${faq_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddFaqsMutation,
  useLazyGetAllFaqsQuery,
  useDeleteFaqMutation,
} = faqApi;
