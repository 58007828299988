import { useState } from "react";
// import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import CrytoTransactionsHistory from "./Crypto";

const ManageInterest = () => {
  // const [activeTab, setActiveTab] = useState("1");

  // const onChange = (key: string) => {
  //   setActiveTab(key);
  // };

  return (
    <div>
      <CrytoTransactionsHistory />
    </div>
  );
};

export default ManageInterest;
