import { Button, Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

const NotificationsHistory = () => {
  const navigate = useNavigate();

  interface DataType {
    key: string;
    title: string;
    description: string;
    serial: number;
    timestamp: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
    },
  ];

  const data: DataType[] = [
    {
      key: "1234",
      title: "Important Platform Update",
      description:
        "Dear users, we have released a new version of our platform with improved security and performance. Please update your app to the latest version.",
      serial: 1,
      timestamp: "23-04-2023 14:30",
    },
    {
      key: "5678",
      title: "Maintenance Notification",
      description:
        "Dear users, our platform will be undergoing maintenance on April 26, 2023, from 2:00 AM to 6:00 AM UTC. During this time, you may experience temporary service disruptions. We apologize for any inconvenience caused.",
      serial: 2,
      timestamp: "22-04-2023 09:45",
    },
    {
      key: "9012",
      title: "Security Alert",
      description:
        "Dear users, we have detected a suspicious login attempt on your account. Please change your password immediately to ensure the safety of your assets.",
      serial: 3,
      timestamp: "20-04-2023 18:20",
    },
    {
      key: "3456",
      title: "New Token Listing",
      description:
        "Dear users, we are pleased to announce the listing of a new token on our platform. The token is now available for trading on our platform. Happy trading!",
      serial: 4,
      timestamp: "18-04-2023 11:15",
    },
    {
      key: "7890",
      title: "Market Volatility Alert",
      description:
        "Dear users, the price of BTC has experienced significant volatility in the past few hours. Please exercise caution while trading.",
      serial: 5,
      timestamp: "16-04-2023 22:05",
    },
  ];

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Notification History</h2>
            <Button
              style={{
                background: "#0C163F",
                width: 100,
                height: 40,
              }}
              type="primary"
              onClick={() => {
                navigate("/notifications", { replace: true });
              }}
            >
              Back
            </Button>
          </div>
          <Table columns={columns} dataSource={data} />
        </div>
      </Card>
    </div>
  );
};

export default NotificationsHistory;
