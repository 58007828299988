import { END_POINTS } from "../helpers";
import { CmsResponse } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostAddCmsResponse = {
  statusCode: number;
  message: string;
};

type PostGetCmsResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type postAddCmsBody = {
  AboutUs: string;
  TermsandConditions: string;
  privacyPolicy: string;
  email: string;
  phone: string;
};

type putUpdateSettingsBody = {
  plateFormFeeType: string;
  plateFormFee: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addCms: builder.mutation<PostAddCmsResponse, postAddCmsBody>({
      query: (body) => ({
        url: END_POINTS.addCms,
        method: "POST",
        body,
      }),
    }),

    getCms: builder.query<PostGetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.getCms,
        method: "GET",
      }),
    }),

    putUpdateCms: builder.mutation<PostAddCmsResponse, postAddCmsBody>({
      query: (body) => ({
        url: END_POINTS.updateCms,
        method: "PUT",
        body,
      }),
    }),

    putUpdateSettings: builder.mutation<
      PostAddCmsResponse,
      putUpdateSettingsBody
    >({
      query: (body) => ({
        url: END_POINTS.Setting,
        method: "PUT",
        body,
      }),
    }),

    getSetting: builder.query<PostGetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.getSetting,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddCmsMutation,
  useLazyGetCmsQuery,
  usePutUpdateCmsMutation,
  usePutUpdateSettingsMutation,
  useLazyGetSettingQuery,
} = cmsApi;
