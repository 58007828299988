import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import EditText from "../../components/textEditor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useAddCmsMutation,
  useLazyGetCmsQuery,
  usePutUpdateCmsMutation,
} from "../../services/cms";
import { errorToast, successToast } from "../../helpers";

const ManageCms = () => {
  // const [countryName, setCountryName] = useState("in");
  const [form] = Form.useForm();
  const [addCms, addCmsMutation] = useAddCmsMutation();
  const [getCms, getCmsData] = useLazyGetCmsQuery();
  const [updateCms, updateCmsMutation] = usePutUpdateCmsMutation();

  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setphoneNo] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [terms, setTerms] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [email, setEmail] = useState("");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setphoneNo(phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const onFinish = async (values: any) => {
    let body = {
      AboutUs: aboutUs,
      email: email,
      phone: phoneNo,
      privacyPolicy: privacyPolicy,
      TermsandConditions: terms,
    };
    try {
      const res = await updateCms(body).unwrap();

      if (res?.statusCode === 200) {
        successToast("CMS Updated Successfully");
      }
    } catch (error: any) {
      console.log(error, "error");
      errorToast(error?.message);
    }
  };

  const getCmsDetail = async () => {
    try {
      const res = await getCms({}).unwrap();
      console.log(res, "Get CMS");
      console.log(res?.data[0]?.AboutUs, "ABOUT");

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          email: res?.data[0]?.email,
        });
        setAboutUs(res?.data[0]?.AboutUs || "");
        setPrivacyPolicy(res?.data[0]?.privacyPolicy || "");
        setTerms(res?.data[0]?.TermsandConditions || "");
        setEmail(res?.data[0]?.email);
        setphoneNo(res?.data[0]?.phone);

        // setPhoneCode(res?.data?.countryCode);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <div style={{ padding: "20px 20px" }}>
      <ContentHeader heading="Manage CMS " />
      <Card>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h3>Privacy Policy</h3>
            {privacyPolicy ? (
              <EditText content={privacyPolicy} setContent={setPrivacyPolicy} />
            ) : null}
          </Col>
          <Col xs={24} md={12}>
            <h3>Terms and Conditions</h3>
            {terms ? <EditText content={terms} setContent={setTerms} /> : null}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <h3>About us</h3>
            {aboutUs ? (
              <EditText content={aboutUs} setContent={setAboutUs} />
            ) : null}
          </Col>
        </Row>

        <h3>Contact Us</h3>
        <Form
          name="basic"
          style={{
            maxWidth: "80%",
          }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <div>
                  <h4>Email</h4>
                  <Input
                    value={email}
                    style={{ height: 55 }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="E-mail"
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <div>
                <h4>Phone Number</h4>
                <div className="phn_cs">
                  <PhoneInput
                    value={phoneCode + phoneNo}
                    enableSearch={true}
                    placeholder="Phone number"
                    containerClass={"cntn_telCls"}
                    inputClass={"cstm_cls"}
                    buttonClass={"custm_btn"}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Row gutter={4}>
          <Col xs={16} md={20}>
            <Button
              style={{
                background: "#1e90ff",
                width: 200,
                height: 50,
                margin: "auto",
                marginTop: 20,
              }}
              type="primary"
              htmlType="submit"
              onClick={onFinish}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManageCms;
